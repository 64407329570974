<template>
  <v-container>
    <!-- PASSWORD RESET -->
    <v-card>
				<v-tabs v-model="tab" centered grow icons-and-text>
					<v-tabs-slider></v-tabs-slider>
					<v-tab class="smaller">{{ $t('auth_edit.tab_password') }}<v-icon>mdi-view-headline</v-icon></v-tab>
					<v-tab class="smaller">{{ $t('auth_edit.tab_user') }}<v-icon>mdi-file-key</v-icon></v-tab>
					<v-tab class="smaller">{{ $t('auth_edit.tab_certification') }}<v-icon>mdi-ballot</v-icon></v-tab>
					<!-- https://pictogrammers.github.io/@mdi/font/5.4.55/ -->
				</v-tabs>
				<v-tabs-items v-model="tab">
					<!-- PASSWORD -->
					<v-tab-item>
						<v-card flat>
							<validation-observer ref="observer" v-slot="{ invalid }">
                <form @submit.prevent="submit_password">
                  <v-card elevation="24">
                    <v-card-title>{{ $t('auth_edit.title_password') }}</v-card-title>
                    <v-card-text>
                      <validation-provider v-slot="{ errors }" name="password" rules="required">
                        <v-text-field v-model="password" :error-messages="errors" :label="$t('auth_edit.password')"  :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1"></v-text-field>
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" name="password_new" rules="required" vid="password_new_confirmation">
                        <v-text-field v-model="password_new" :error-messages="errors" :label="$t('auth_edit.password_new')"  :type="show2 ? 'text' : 'password'" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show2 = !show2"></v-text-field>
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" name="password_new_confirm" rules="required|custom_password:password_new_confirmation">
                        <v-text-field v-model="password_new_confirm" :error-messages="errors" :label="$t('auth_edit.password_new_confirm')"  :type="show3 ? 'text' : 'password'" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show3 = !show3"></v-text-field>
                      </validation-provider>
                      <v-btn block :disabled="invalid" type="submit">{{$t('auth_edit.btn_change_password')}}</v-btn>
                    </v-card-text>
                  </v-card>
                </form>
              </validation-observer>
						</v-card>
					</v-tab-item>
					<!-- USER DATA -->
					<v-tab-item>
						<v-card flat>
              <validation-observer ref="observer2" v-slot="{ invalid }">
                <form @submit.prevent="submit_reg_data">
                  <v-card elevation="24">
                    <v-card-title>{{ $t('auth_edit.title_user') }}</v-card-title>
                    <v-card-text>
                      <validation-provider v-slot="{ errors }" name="name" :rules="{ required: true, custom_alpha_string: /^[A-Za-zÀ-ž0-9 -]*$/ }">
                        <v-text-field v-model="user.name" :error-messages="errors" :label="$t('auth_edit.name')"></v-text-field>
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" name="surname" :rules="{ required: true, custom_alpha_string: /^[A-Za-zÀ-ž0-9 -]*$/ }">
                        <v-text-field v-model="user.surname" :error-messages="errors" :label="$t('auth_edit.surname')"></v-text-field>
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" name="company" :rules="{required: true, custom_ico:8 }">
                        <v-autocomplete v-model="company" :loading="ico_is_loading" :items="ico_items" :search-input.sync="ico_search" :error-messages="errors" :label="$t('auth_edit.ico')" item-text="ico" item-value="ico" return-object hide-no-data clearable>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title v-text="item.company"></v-list-item-title>
                              <v-list-item-subtitle v-text="item.address"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </validation-provider>
                      <v-text-field :label="$t('auth_edit.company')" disabled v-model="ico_details"></v-text-field>
                      <validation-provider v-slot="{ errors }" name="position" :rules="{ required: true, custom_alpha_string: /^[A-Za-zÀ-ž0-9 -]*$/ }">
                        <v-text-field v-model="user.position"  :error-messages="errors" :label="$t('auth_edit.position')"></v-text-field>
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" name="telephone" :rules="{ required: true, custom_telephone: /^\+421[0-9]{9,9}$/ }">
                        <v-text-field v-model="user.tel" :error-messages="errors" :label="$t('auth_edit.telephone')"></v-text-field>
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                        <v-text-field v-model="user.email" :error-messages="errors" :label="$t('auth_edit.email')"></v-text-field>
                      </validation-provider>
                      <v-radio-group v-model="user.enews" :label="$t('auth_edit.enews')">
                        <v-radio :label="$t('auth_edit.enews_yes')" value="yes" checked></v-radio>
                        <v-radio :label="$t('auth_edit.enews_no')" value="no"></v-radio>
                      </v-radio-group>
                      <v-btn block :disabled="invalid" type="submit">{{$t('auth_edit.btn_change_user_data')}}</v-btn>
                    </v-card-text>
                  </v-card>
                </form>
              </validation-observer>
						</v-card>
					</v-tab-item>
					<!-- CERTIFICATION -->
					<v-tab-item>
						<v-card flat>
              <validation-observer ref="observer3" v-slot="{ invalid }">
                <form @submit.prevent="submit_personal_data">
                  <v-card elevation="24">
                    <v-card-title>
                      {{$t('auth_edit.title_certification')}}
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="user.certDataTitleBefore" :error-messages="errors" :label="$t('auth_edit.title_before')"></v-text-field>
                      <validation-provider v-slot="{ errors }" name="name" :rules="{ required: true, custom_alpha_string: /^[A-Za-zÀ-ž0-9 -]*$/ }">
                        <v-text-field v-model="user.name" :error-messages="errors" :label="$t('auth_edit.name')"></v-text-field>
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" name="surname" :rules="{ required: true, custom_alpha_string: /^[A-Za-zÀ-ž0-9 -]*$/ }">
                        <v-text-field v-model="user.surname" :error-messages="errors" :label="$t('auth_edit.surname')"></v-text-field>
                      </validation-provider>
                        <v-text-field v-model="user.certDataTitleAfter" :error-messages="errors" :label="$t('auth_edit.title_after')"></v-text-field>

                      <template>
                        <v-menu ref="birth_menu" v-model="birth_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px" >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="user.certDataBirthday" :label="$t('auth_edit.birthday')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker class="najvyssi" ref="picker" v-model="user.certDataBirthday" :max="new Date().toISOString().substr(0, 10)" min="1950-01-01" @change="birth_save"
                          ></v-date-picker>
                        </v-menu>
                      </template>

                      <validation-provider v-slot="{ errors }" name="address" rules="required">
                        <v-text-field v-model="user.certDataAddress" :error-messages="errors" :label="$t('auth_edit.address')"></v-text-field>
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" name="zipcode" rules="required|digits:5">
                        <v-text-field v-model="user.certDataPSC" :error-messages="errors" :label="$t('auth_edit.zipcode')"></v-text-field>
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" name="city" :rules="{ required: true, custom_alpha_string: /^[A-Za-zÀ-ž0-9 -]*$/ }">
                        <v-text-field v-model="user.certDataCity" :error-messages="errors" :label="$t('auth_edit.city')"></v-text-field>
                      </validation-provider>
                      <validation-provider v-slot="{ errors }" name="country" :rules="{ required: true, custom_alpha_string: /^[A-Za-zÀ-ž0-9 -]*$/ }">
                        <v-text-field v-model="user.certDataCountry" :error-messages="errors" :label="$t('auth_edit.country')"></v-text-field>
                      </validation-provider>
                      {{ $t('auth_edit.licence_for') }}
                      <v-checkbox v-model="licences_selected" v-for="licence in licences" :key="licence.id" :label="licence.label" :value="licence.id"></v-checkbox>
                      <v-btn v-if="edu_confirm" block :disabled="invalid" type="submit">{{$t('auth_edit.btn_confirm_certification_data')}}</v-btn>
                      <v-btn v-else block :disabled="invalid" type="submit">{{$t('auth_edit.btn_change_certification_data')}}</v-btn>
                    </v-card-text>
                  </v-card>
                </form>
              </validation-observer>							
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-card>
  </v-container>
</template>

<script>
import { i18n } from "@/i18n/i18n"
import ApiService from "@/services/api_service"
//import { sha256 } from "js-sha256";

import {
  ValidationObserver,
  ValidationProvider,
  configure,
  extend,
  setInteractionMode
} from "vee-validate"

import {
  alpha,
  confirmed,
  digits,
  email,
  regex,
  required
} from "vee-validate/dist/rules"

setInteractionMode("aggressive");

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`validation.${values._rule_}`, values)
  }
})
extend("alpha", alpha);
extend("digits", digits);
extend("email", email);
extend("custom_gdpr", { ...required });
extend("custom_ico", { 
  validate(value, args) {
    return value.ico.length == args.length
  },
  params: ["length"]
})
extend("custom_password", { ...confirmed });
extend("custom_telephone", { ...regex });
extend("custom_alpha_string", { ...regex });
extend("custom_psc", { ...regex });
extend("required", required);

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    drawer: false,
		group: null,
		
		tab: null,
    edu_confirm: false, 
    user: [],

    password: "",
    password_new: "",
    password_new_confirm: "",

    current_user_id: -1,

    company:  null,
    ico_is_loading: false,
    ico_items: [],
    ico_search: null,
    ico_details: "",

    
    birth_menu: false,
   
    show1: false,
    show2: false,
    show3: false,
    licences: [
      {
        id: 'design',
        label: i18n.t('auth_edit.licence_design')
      },
      {
        id: 'installation',
        label: i18n.t('auth_edit.licence_installation')
      },
      {
        id: 'inspection',
        label: i18n.t('auth_edit.licence_inspection')
      }
    ],
    licences_selected: [],
    errors: null
  }),
  watch: {
    group() {
			this.drawer = false;
		},
    company(val) {
      // after ico had been selected
      if(val != null) {
        this.ico_details = val.company + ', ' + val.address
      }
      else {
        this.ico_details = ""
      }
    },
    ico_search(val) {
      // search values
      val && val !== this.user.sd_company && val.length == 8 && this.querySelections(val)
    },
    birth_menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  methods: {
    birth_save(date) {
      this.$refs.birth_menu.save(date)
    },
    querySelections(val) {
      ApiService.get(`/external/ico/${val}`)
        .then(response => {
          this.ico_items = response.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.ico_is_loading = false))
    },
    submit_password() {
      this.$refs.observer.validate();
      if(this.errors == null) {
        ApiService.post('/auth/change', {
          ID_user: this.$store.getters.get_user_id,
          //password: sha256(this.password),
          password: this.password,
          //password_new: sha256(this.password_new)
          password_new: this.password_new
        })
        .then(response => {
          console.log(response)
        })
        .catch(() => {
        })
      }
    },
    submit_reg_data() {
      this.$refs.observer2.validate();
      if(this.errors == null) {
        console.log(this.company)
        ApiService.post('/auth/edit', {
          ID_user: this.$store.getters.get_user_id,
          name: this.user.name,
          surname: this.user.surname,
          sd_company: this.company.company,
          sd_formatted_address: this.company.address,
          sd_ico: this.company.ico,
          position: this.user.position,
          tel: this.user.tel,
          email: this.user.email,
          enews: this.user.enews
        })
        .then(response => {
          console.log(response)
        })
        .catch(() => {
        })
      }
    },
    submit_personal_data() {
      this.$refs.observer3.validate();
      if(this.errors == null) {
        ApiService.post('/auth/personal', {
          ID_user: this.$store.getters.get_user_id,
          certDataTitleBefore: this.user.certDataTitleBefore,
          certDataName: this.user.name,
          certDataSurname: this.user.surname,
          certDataTitleAfter: this.user.certDataTitleAfter,
          certDataBirthday: this.user.certDataBirthday,
          certDataAddress: this.user.certDataAddress,
          certDataPSC: this.user.certDataPSC,
          certDataCity: this.user.certDataCity,
          certDataCountry: this.user.certDataCountry,
          certDataPMK: this.licences_selected
        })
        .then(response => {
          console.log(response)
          if(typeof this.$route.params.tabid !== 'undefined') {
            this.$router.go(-1)
          }
        })
        .catch(() => {
        })
      }
    }
  },

  mounted() {
if(typeof this.$route.params.tabid !== 'undefined') {
        this.tab = 2
        this.edu_confirm = true
      }
    this.current_user_id = this.$store.getters.get_user_id
    ApiService.get("/auth/info/" + this.current_user_id).then((response) => {
			// AUTHORIZED WITH FILES
      console.log(response.data)
      this.user = response.data
      this.company = {
        company: response.data.sd_company,
        address: response.data.sd_formatted_address,
        ico: response.data.sd_ico
      }
      this.ico_search = response.data.sd_ico
      
      // TODO LOAD PMK 0,0,0 to installation, design, etc
      var pmk = JSON.parse(response.data.certDataPMK)
      if(pmk[0] == 1) this.licences_selected.push('design')
      if(pmk[1] == 1) this.licences_selected.push('installation')
      if(pmk[2] == 1) this.licences_selected.push('inspection')
		}).catch(() => {})
   
  }
};
</script>

<style scoped>
.najvyssi {
  z-index: 9999;
}
</style>
